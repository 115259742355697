<template>
  <deep-settings-select
    v-if="isSelect"
    :field-key="field"
    :size="size"
    :current-value="currentValue"
    :is-required="isRequired"
    @updateValue="updateValue"
  >
    <slot />
  </deep-settings-select>
  <deep-settings-input
    :id="field"
    :value="currentValue"
    :size="size"
    :is-required="isRequired"
    @updateValue="updateValue"
    v-else
  >
    <slot />
  </deep-settings-input>
</template>

<script>
import DeepSettingsSelect from '@/components/smart/DeepSettings/DeepSettingsSelect'
import DeepSettingsInput from '@/components/smart/DeepSettings/DeepSettingsInput'

export default {
  props: {
    field: {
      type: String,
      required: true
    },
    currentFieldConfig: {
      type: Object,
      required: true
    },
    currentValue: {
      type: [String, Number]
    }
  },
  name: 'DeepSettingsFieldHOC',
  components: { DeepSettingsInput, DeepSettingsSelect },
  methods: {
    updateValue(val) {
      this.$emit('updateValue', val)
    }
  },
  computed: {
    size() {
      return this.currentFieldConfig.size
    },
    isRequired() {
      return this.currentFieldConfig.isRequired
    },
    isSelect() {
      return this.currentFieldConfig.isSelect
    }
  }
}
</script>

<style scoped></style>
