<template>
  <div class="object-data">
    <form-title><slot /></form-title>
    <div class="fields">
      <div class="fields__left">
        <deep-settings-input
          :id="keys.name"
          size="xl"
          :value="fields.name"
          @updateValue="updateParam($event, keys.name)"
          is-required
        >
          Объект
        </deep-settings-input>
        <deep-settings-input
          v-click-outside="hideSuggestions"
          :id="keys.address"
          size="xl"
          :value="fields.address"
          @updateValue="updateParam($event, keys.address)"
          @focus="handleAddressInputFocus"
        >
          Адрес объекта
          <template #suggestions>
            <transition name="fade">
              <address-suggestions
                :suggestions="suggestions"
                v-if="isShowSuggestions"
                @updateAddress="updateAddress($event, keys.address)"
              />
            </transition>
          </template>
        </deep-settings-input>
        <deep-settings-input
          :id="keys.CRMNumber"
          size="xs"
          :value="fields.CRMNumber"
          @updateValue="updateParam($event, keys.CRMNumber)"
        >
          № объекта в CRM
        </deep-settings-input>
      </div>
      <div class="fields__center">
        <deep-settings-select
          :field-key="keys.stage"
          :current-value="fields.stage"
          size="md"
          @updateValue="updateParam($event, keys.stage)"
        >
          Текущая стадия
        </deep-settings-select>
        <deep-settings-select
          :field-key="keys.objectRegion"
          :current-value="fields.objectRegion"
          size="md"
          @updateValue="updateParam($event, keys.objectRegion)"
        >
          Регион объекта
        </deep-settings-select>
      </div>
      <div class="fields__right">
        <deep-settings-input is-disabled :id="keys.square" size="xs" :value="fields.square">
          Площадь конструкции, м²
        </deep-settings-input>
        <deep-settings-input
          is-disabled
          :id="keys.sectorsCount"
          size="xs"
          :value="fields.sectorsCount"
        >
          К-во участков
        </deep-settings-input>
      </div>
    </div>
  </div>
</template>

<script>
import { sumElements } from '@/utils/customFunctions'
import { checkIsFormValid, objectDataKeys } from '@/utils/deepSettings'
import FormTitle from '@/components/smart/DeepSettings/FormTitle'
import DeepSettingsInput from '@/components/smart/DeepSettings/DeepSettingsInput'
import DeepSettingsSelect from '@/components/smart/DeepSettings/DeepSettingsSelect'
import { mapState, mapActions } from 'vuex'
import { getAddressSuggestions } from '@/api/suggestions-api'
import AddressSuggestions from '@/components/smart/DeepSettings/AddressSuggestions'
export default {
  name: 'ObjectDataForm',
  props: {
    isFormValid: Boolean
  },
  data: () => ({
    suggestions: [],
    isShowSuggestions: false
  }),
  components: { AddressSuggestions, DeepSettingsSelect, DeepSettingsInput, FormTitle },
  methods: {
    ...mapActions('moderator', {
      updateObjectDataParam: 'updateObjectDataParam'
    }),
    updateParam(val, param) {
      this.updateObjectDataParam({
        param,
        val
      })
    },
    updateAddress($event, param) {
      this.updateParam($event, param)
      this.hideSuggestions()
    },
    handleAddressInputFocus() {
      this.showSuggestions()
    },
    showSuggestions() {
      if (!this.isShowSuggestions) {
        this.isShowSuggestions = true
      }
    },
    hideSuggestions() {
      if (this.isShowSuggestions) {
        this.isShowSuggestions = false
      }
    },
    getSuggestions(query) {
      getAddressSuggestions(query).then(response => {
        this.suggestions = Object.freeze(response.data.suggestions)
      })
    }
  },
  mounted() {
    const { sectorsCount, square } = this.fields
    const { sectors } = this
    if (!sectorsCount) {
      this.updateParam(sectors.length, this.keys.sectorsCount)
    }
    if (!square) {
      this.updateParam(sumElements(sectors, this.keys.square), this.keys.square)
    }
  },
  watch: {
    computedIsFormValid: {
      handler(newVal) {
        this.$emit('update:isFormValid', newVal)
      },
      deep: true,
      immediate: true
    },
    'fields.address'(newVal) {
      this.getSuggestions(newVal)
    }
  },
  computed: {
    ...mapState('moderator', {
      fields: state => state.objectData
    }),
    ...mapState({
      sectors: state => state.sectors
    }),
    keys: () => objectDataKeys,
    requiredFields() {
      return [this.keys.name]
    },
    computedIsFormValid() {
      return checkIsFormValid(this.fields, this.requiredFields)
    }
  }
}
</script>

<style scoped lang="sass">
.object-data
  @extend %common-aplication-data-forms-styles

.fade-enter-active, .fade-leave-active
  transition: opacity .5s

.fade-enter, .fade-leave-to
  opacity: 0
</style>
