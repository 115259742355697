<template>
  <div class="excel-uploader">
    <label for="xls" class="excel-uploader__label" :class="{ 'is-loading': this.isLoading }">
      <input type="file" id="xls" name="userFile" hidden @change="addXls($event)" />
      <span class="icon">
        <img src="@/assets/img/icons/deep-settings/import-excel.svg" alt="" />
      </span>
      Импорт формы из Excel
      <loading
        :active.sync="isLoading"
        :can-cancel="false"
        color="#000"
        :height="30"
        :width="30"
        :opacity="1"
        backgroundColor="transparent"
        :z-index="3"
        :is-full-page="false"
      ></loading>
    </label>
    <error v-if="isErrorsShow"> Недопустимый формат файла допустимые форматы: xls, xlsx </error>
  </div>
</template>

<script>
import { exportDataFromExcel } from '@/api/moderator'
import Error from '@/components/smart/CalculatorRating/error'
import { handleError } from '@/utils/deepSettings'
import { mapMutations } from 'vuex'
const xlsxFileTypes = [
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.ms-excel'
]

export default {
  name: 'ExcelUploader',
  components: { Error },
  data: () => ({
    isErrorsShow: false,
    isLoading: false
  }),
  methods: {
    ...mapMutations('moderator', {
      SET_DATA_FROM_EXCEL_IMPORT: 'SET_DATA_FROM_EXCEL_IMPORT',
      SET_FIlES_FROM_EXCEL_IMPORT: 'SET_FIlES_FROM_EXCEL_IMPORT'
    }),
    addXls(e) {
      const regex = new RegExp('([a-zA-Zа-яА-Я0-9\\s_\\\\.\\-():])+(.xls|.xlsx)$')
      if (e.target.files.length > 0) {
        const [file] = e.target.files
        const isNameValid = regex.test(file.name)
        const isFileTypeValid = xlsxFileTypes.includes(file.type)
        this.isErrorsShow = ![isNameValid, isFileTypeValid].every(val => !!val)
        if (!this.isErrorsShow) {
          this.isLoading = true
          exportDataFromExcel(file)
            .then(response => {
              const { data } = response
              if (data.data) {
                this.SET_DATA_FROM_EXCEL_IMPORT(data.data)
              }
              if (data.errors) {
                data.errors.forEach(error => {
                  handleError(this.$notify, error)
                })
              }
              if (data.files) {
                this.SET_FIlES_FROM_EXCEL_IMPORT(data.files)
              }
              this.isLoading = false
            })
            .catch(e => {
              handleError(this.$notify, e)
              this.isLoading = false
            })
        }
      }
    }
  }
}
</script>

<style scoped lang="sass">
.excel-uploader
  display: flex
  flex-direction: column
  align-items: flex-start
  margin-bottom: rem(40)
  .error
    margin-top: rem(10)
    position: relative
    transform: none
  &__label
    display: flex
    border-radius: rem(4)
    align-items: center
    padding: rem(12) rem(16)
    cursor: pointer
    background: $ec
    font-weight: 600
    font-size: rem(14)
    line-height: rem(16)
    color: $light-black
    transition: .3s
    position: relative
    .vld-overlay
      line-height: 0
    &:not(.is-loading)
      @media (any-hover: hover)
        &:hover
          color: $white
          background: $black
          .icon
            filter: invert(84%) sepia(0%) saturate(54%) hue-rotate(234deg) brightness(100%) contrast(98%)
    .icon
      filter: invert(72%) sepia(5%) saturate(15%) hue-rotate(328deg) brightness(84%) contrast(86%)
      line-height: 0
      margin-right: rem(10)
      transition: .3s
</style>
