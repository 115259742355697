<template>
  <div :class="classes">
    <label :for="`input--${id}`" class="label">
      <slot />
      <required-pin v-if="isRequired" />
    </label>
    <input
      class="field"
      :id="`input--${id}`"
      type="text"
      v-model="computedValue"
      :disabled="isDisabled"
      @focus="handleFocus"
    />
    <slot name="suggestions" v-if="$slots.suggestions" />
  </div>
</template>

<script>
export default {
  name: 'DeepSettingsInput',
  components: { RequiredPin: () => import('@/components/smart/DeepSettings/requiredPin') },
  props: {
    size: {
      type: String,
      default: 'xs',
      validator: v => ['xs', 'md', 'lg', 'xl'].includes(v)
    },
    isDisabled: Boolean,
    id: {
      type: String,
      required: true
    },
    value: {
      type: [String, Number],
      default: null
    },
    isRequired: Boolean
  },
  methods: {
    handleFocus() {
      this.$emit('focus')
    }
  },
  computed: {
    classes() {
      return {
        'deep-settings-input': true,
        [`deep-settings-input--${this.size}`]: this.size
      }
    },
    computedValue: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('updateValue', val)
      }
    }
  }
}
</script>

<style scoped lang="sass">
.deep-settings-input
  position: relative
  @extend %deep-settings-field-styles
  &--xs
    @extend %deep-settings-field-styles--xs
  &--md
    @extend %deep-settings-field-styles--md
  &--lg
    @extend %deep-settings-field-styles--lg
  &--xl
    @extend %deep-settings-field-styles--xl
  .field
    text-overflow: ellipsis
    overflow: hidden
</style>
