<template>
  <main>
    <excel-uploader />
    <div class="common-data-step">
      <application-data-form :is-form-valid.sync="isApplicationDataFormValid">
        Данные заявки
      </application-data-form>
      <object-data-form :is-form-valid.sync="isObjectDataFormValid" v-if="!isLoading">
        Данные объекта
      </object-data-form>
      <customer-client-data-form :is-form-valid.sync="isCustomerClientDataFormValid">
        Данные заказчика
        <template #clientDataTitle> Данные клиента </template>
      </customer-client-data-form>
      <steps-nav
        :is-next-step-disabled="isNextStepDisabled"
        :current-step="currentStep"
        @prev="prev"
        @next="next"
      />
    </div>
  </main>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import ApplicationDataForm from '@/components/smart/DeepSettings/CommonDataStepForms/ApplicationDataForm'
import ObjectDataForm from '@/components/smart/DeepSettings/CommonDataStepForms/ObjectDataForm'
import StepsNav from '@/components/smart/DeepSettings/StepsNav'
import ExcelUploader from '@/components/smart/DeepSettings/ExcelUploader'
import CustomerClientDataForm from '@/components/smart/DeepSettings/CommonDataStepForms/CustomerClientDataForm'

export default {
  name: 'CommonDataStep',
  props: {
    currentStep: {
      type: String,
      required: true
    },
    isLoading: {
      type: Boolean
    }
  },
  components: {
    CustomerClientDataForm,
    ExcelUploader,
    StepsNav,
    ObjectDataForm,
    ApplicationDataForm
  },
  data: () => ({
    isApplicationDataFormValid: false,
    isObjectDataFormValid: false,
    isCustomerClientDataFormValid: false
  }),
  methods: {
    next() {
      this.$emit('next')
    },
    prev() {
      const { lang, hash } = this.$route.params
      this.$router.push({ name: 'Result', params: { lang }, query: { object: hash } })
    },
    ...mapMutations('moderator', {
      UPDATE_VALID_FORM: 'UPDATE_VALID_FORM'
    })
  },
  computed: {
    ...mapState('moderator', {
      currentCustomerType: state => state.customerData.customer
      // RTPCustomerId: state => state.RTPCustomerId
    }),

    // isRTPCustomer() {
    //   return this.currentCustomerType === this.RTPCustomerId
    // },
    isNextStepDisabled() {
      const {
        isApplicationDataFormValid,
        isObjectDataFormValid,
        isCustomerClientDataFormValid
      } = this
      const defaultValidations = [
        isApplicationDataFormValid,
        isObjectDataFormValid,
        isCustomerClientDataFormValid
      ]
      // const currentValidations = isRTPCustomer
      //   ? [...defaultValidations, isClientDataFormValid]
      //   : defaultValidations
      this.UPDATE_VALID_FORM(defaultValidations.some(status => status === false))
      return defaultValidations.some(status => status === false)
    }
  }
}
</script>

<style scoped lang="sass">
.common-data-step
  display: grid
  gap: rem(64)
</style>
