<template>
  <div class="application-data">
    <form-title><slot /></form-title>
    <div class="fields">
      <div class="fields__left">
        <deep-settings-date-picker
          size="md"
          :date="fields.startDate"
          @updateDate="updateStartDate($event, keys.startDate)"
          is-required
        >
          Дата поступления исходных данных
        </deep-settings-date-picker>
        <deep-settings-input
          :id="keys.applicationNumber"
          size="xs"
          :value="fields.applicationNumber"
          @updateValue="updateParam($event, keys.applicationNumber, true)"
        >
          Номер заявки
        </deep-settings-input>
        <deep-settings-input
          :id="keys.promoCode"
          :value="fields.promoCode"
          size="xs"
          @updateValue="updateParam($event, keys.promoCode)"
        >
          Промокод
        </deep-settings-input>
      </div>
      <div class="fields__center">
        <deep-settings-date-picker
          size="md"
          :date="fields.endDate"
          @updateDate="updateEndDate($event, keys.endDate)"
          :min-date="fields.startDate"
          is-required
        >
          Дата выдачи расчета
        </deep-settings-date-picker>
        <deep-settings-input
          :id="keys.variantsCount"
          :value="fields.variantsCount"
          size="xs"
          @updateValue="updateParam($event, keys.variantsCount, true)"
          is-required
        >
          К-во вариантов расчета
        </deep-settings-input>
        <deep-settings-input
          :id="keys.variantNumber"
          :value="fields.variantNumber"
          size="xs"
          @updateValue="updateParam($event, keys.variantNumber, true)"
          is-required
        >
          Номер варианта расчета
        </deep-settings-input>
      </div>
      <div class="fields__right">
        <deep-settings-select
          :field-key="keys.calculationType"
          :current-value="fields.calculationType"
          size="md"
          @updateValue="updateParam($event, keys.calculationType, true)"
          is-required
        >
          Тип расчета
        </deep-settings-select>
        <deep-settings-input
          :id="keys.calculationNumber"
          :value="fields.calculationNumber"
          size="xs"
          @updateValue="updateParam($event, keys.calculationNumber, true)"
          is-required
        >
          Номер расчета
        </deep-settings-input>
        <deep-settings-input :id="keys.cipher" :value="fields.cipher" size="md" is-disabled>
          Шифр расчета (генерируется)
        </deep-settings-input>
      </div>
    </div>
  </div>
</template>

<script>
import { applicationDataKeys, checkIsFormValid, generateCipher } from '@/utils/deepSettings'
import DeepSettingsDatePicker from '@/components/smart/DeepSettings/DeepSettingsDatePicker'
import DeepSettingsInput from '@/components/smart/DeepSettings/DeepSettingsInput'
import DeepSettingsSelect from '@/components/smart/DeepSettings/DeepSettingsSelect'
import { mapState, mapActions, mapMutations } from 'vuex'
import FormTitle from '@/components/smart/DeepSettings/FormTitle'
import { applicationData } from '@/utils/InitialDeepSettingsState'
export default {
  name: 'ApplicationDataForm',
  props: {
    isFormValid: Boolean
  },
  methods: {
    ...mapActions('moderator', {
      updateApplicationDataParam: 'updateApplicationDataParam'
    }),
    ...mapMutations('moderator', {
      UPDATE_PARAM: 'UPDATE_PARAM'
    }),
    updateParam(val, param, isNeedGenerateCipher = false) {
      this.updateApplicationDataParam({
        param,
        val,
        isNeedGenerateCipher
      })
    },
    updateStartDate(val, param) {
      if (val) {
        this.updateApplicationDataParam({
          param,
          val,
          isNeedGenerateCipher: true
        })
        if (val > this.fields.endDate) {
          this.updateApplicationDataParam({
            param: [this.keys.endDate],
            val,
            isNeedGenerateCipher: true
          })
        }
      }
    },
    updateEndDate(val, param) {
      if (val && val >= this.fields.startDate) {
        this.updateApplicationDataParam({
          param,
          val,
          isNeedGenerateCipher: true
        })
        // if (val < this.fields.startDate) {
        //   this.updateApplicationDataParam({
        //     param: [this.keys.startDate],
        //     val,
        //     isNeedGenerateCipher: true
        //   })
        // }
      }
    }
  },
  watch: {
    computedIsFormValid: {
      handler(newVal) {
        this.$emit('update:isFormValid', newVal)
      },
      deep: true,
      immediate: true
    }
  },
  mounted() {
    const { userId, fields, calculationPrice, currentCipher, calculationTypes } = this
    const {
      calculationNumber,
      endDate,
      calculationType,
      variantNumber,
      variantsCount,
      applicationNumber
    } = fields
    const newCipher = generateCipher(
      userId,
      calculationNumber,
      endDate,
      calculationPrice,
      calculationType,
      variantNumber,
      variantsCount,
      applicationNumber,
      calculationTypes
    )
    if (currentCipher !== newCipher) {
      this.UPDATE_PARAM({
        key: applicationData,
        param: applicationDataKeys.cipher,
        val: generateCipher(
          userId,
          calculationNumber,
          endDate,
          calculationPrice,
          calculationType,
          variantNumber,
          variantsCount,
          applicationNumber,
          calculationTypes
        )
      })
    }
  },
  computed: {
    ...mapState('moderator', {
      fields: state => state.applicationData,
      userId: state => state.userId,
      calculationPrice: state => state.calculationPrice,
      currentCipher: state => state.applicationData.cipher,
      calculationTypes: state => state.calculationTypes
    }),

    keys: () => applicationDataKeys,
    requiredFields() {
      const {
        calculationNumber,
        startDate,
        endDate,
        variantNumber,
        variantsCount,
        calculationType
      } = this.keys
      return [calculationNumber, startDate, endDate, variantNumber, variantsCount, calculationType]
    },
    computedIsFormValid() {
      return checkIsFormValid(this.fields, this.requiredFields)
    }
  },
  components: { FormTitle, DeepSettingsSelect, DeepSettingsInput, DeepSettingsDatePicker }
}
</script>

<style scoped lang="sass">
.application-data
  @extend %common-aplication-data-forms-styles
  // .fields
    display: flex
    gap: rem(50)
    flex-wrap: wrap
    &__left, &__center, &__right
      display: grid
      gap: rem(24)
</style>
