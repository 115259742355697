<template>
  <div class="suggestions">
    <div class="suggestions__hint" v-if="!!suggestions.length">
      {{ $t('message.save.form.suggestions') }}
    </div>
    <div
      class="suggestions__item"
      v-for="(suggestion, i) in suggestions"
      :key="`${suggestion.data.house_fias_id}--${suggestion.data.city_fias_id}--${i}`"
      @click="updateAddress(suggestion.value)"
    >
      {{ suggestion.value }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'AddressSuggestions',
  props: {
    suggestions: {
      type: Array,
      required: true
    }
  },
  methods: {
    updateAddress(val) {
      this.$emit('updateAddress', val)
    }
  }
}
</script>

<style scoped lang="sass">
.suggestions
  background: $white
  position: absolute
  left: 0
  z-index: 1
  bottom: 0
  transform: translateY(100%)
  width: 100%
  box-shadow: 0 rem(4) rem(12) rgba(0, 0, 0, 0.12)
  max-height: rem(256)
  box-sizing: border-box
  overflow-y: auto
  overflow-x: hidden
  scrollbar-width: thin
  scrollbar-color: rgba($red, .25) transparent
  &::-webkit-scrollbar
    width: rem(4)
  &::-webkit-scrollbar-thumb
    background: $cool_A
  &::-webkit-scrollbar-thumb
    background-color: rgba($red, .25)
    border-radius: 0
  &::-webkit-scrollbar-track
    background: $cool_A
  &__hint
    @extend %12
    color: $light_black
    padding: 0 rem(12)
    margin: rem(6) 0
  &__item
    overflow: hidden
    white-space: nowrap
    text-overflow: ellipsis
    padding: rem(8) rem(6) rem(8) rem(12)
    box-sizing: border-box
    transition: .5s
    cursor: pointer
    @extend %14
    color: $black
    &:not(:last-child)
      box-shadow: inset 0 rem(-1) 0 $ec
    @media(any-hover: hover)
      &:hover
        color: $white
        background: $red
        box-shadow: none
</style>
