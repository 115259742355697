<template>
  <div class="customer-client-data">
    <form-title><slot /></form-title>
    <deep-settings-field-h-o-c
      v-for="field in currentFormConfig.customer || currentFormConfig"
      :key="field"
      :field="field"
      :current-field-config="customerOrClientsDataFieldsConfigs[field]"
      :current-value="fields[field]"
      @updateValue="updateParam($event, field)"
    >
      {{ customerOrClientsDataFieldsConfigs[field].label }}
    </deep-settings-field-h-o-c>
    <template v-if="isClientFieldsNeed">
      <form-title>
        <slot name="clientDataTitle" />
      </form-title>
      <deep-settings-field-h-o-c
        v-for="field in currentFormConfig.client"
        :key="field"
        :field="field"
        :current-field-config="customerOrClientsDataFieldsConfigs[field]"
        :current-value="fields[field]"
        @updateValue="updateParam($event, field)"
      >
        {{ customerOrClientsDataFieldsConfigs[field].label }}
      </deep-settings-field-h-o-c>
    </template>
  </div>
</template>

<script>
import { checkIsFormValid, customerOrClientsDataKeys } from '@/utils/deepSettings'
import { mapState, mapActions } from 'vuex'
import FormTitle from '@/components/smart/DeepSettings/FormTitle'
import DeepSettingsFieldHOC from '@/components/smart/DeepSettings/CommonDataStepForms/DeepSettingsFieldHOC'

const {
  customer,
  position,
  name,
  email,
  phone,
  city,
  INN,
  KPP,
  clientType,
  organization,
  clientRegion,
  other
} = customerOrClientsDataKeys

// Вариант 1.1.  Тип клиента  = 1. Проектировщик | 2. Заказчик | 3. Торговый партнёр | 4. Подрядчик
const formConfig1SubConfig1 = {
  customer: [customer, name, phone, email],
  client: [clientType, organization, city, INN, KPP]
}

// Вариант 1.2.  Тип клиента  = 5. Частное лицо | 11. Прочее
const formConfig1SubConfig2 = {
  customer: [customer, name, phone, email],
  client: [clientType, city]
}

// Вариант 1.3.  Тип клиента  = 6. ОЦО КП | 7. СБЕ | 8. СК | 9. ИТЦ | 10. Интернет магазин
const formConfig1SubConfig3 = {
  customer: [customer, name, phone, email],
  client: [clientType]
}

// Вариант 1.  заказчик = РТП
const formConfig1 = {
  subConfig1: formConfig1SubConfig1,
  subConfig2: formConfig1SubConfig2,
  subConfig3: formConfig1SubConfig3
}

// Вариант 2.  заказчик =  Торговый партнёр | Проектировщик | Заказчик | Подрядчик
const formConfig2 = [
  customer,
  name,
  position,
  phone,
  email,
  organization,
  city,
  clientRegion,
  INN,
  KPP
]

// Вариант 3.  заказчик = ОЦО КП | СБЕ | Частное лицо | СК | ИТЦ | Интернет магазин
const formConfig3 = [customer, name, phone, email, city]

// Вариант 4.  заказчик =  прочее
const formConfig4 = [customer, name, phone, email, organization, city, clientRegion, other]

const configDefault = {
  customer: [customer, name, position, email, phone],
  client: [clientType, clientRegion, INN, organization, city, KPP]
}

const formConfigs = {
  formConfig1,
  formConfig2,
  formConfig3,
  formConfig4,
  configDefault
}

const config1SubConfig1Variants = [405, 406, 407, 408]
const config1SubConfig2Variants = [409, 415]
const config1SubConfig3Variants = [410, 411, 412, 413, 414]

const config1Variants = [380]
const config2Variants = [381, 384, 385, 386]
const config3Variants = [382, 383, 387, 388, 389, 390]
const config4Variants = [391]

const customerOrClientsDataFieldsConfigs = {
  [customer]: {
    label: 'Заказчик',
    size: 'md',
    isRequired: true,
    isSelect: true
  },
  [position]: {
    label: 'Должность',
    size: 'md'
  },
  [name]: {
    label: 'ФИО',
    size: 'lg'
  },
  [email]: {
    label: 'Email',
    size: 'lg'
  },
  [phone]: {
    label: 'Телефон',
    size: 'xs'
  },
  [city]: {
    label: 'Город',
    size: 'md'
  },
  [INN]: {
    label: 'ИНН',
    size: 'xs'
  },
  [KPP]: {
    label: 'КПП',
    size: 'xs'
  },
  [clientType]: {
    label: 'Тип клиента',
    size: 'md',
    isRequired: true,
    isSelect: true
  },
  [organization]: {
    label: 'Организация',
    size: 'lg'
  },
  [clientRegion]: {
    label: 'Регион',
    size: 'md',
    isSelect: true
  },
  [other]: {
    label: 'Другое назначение',
    size: 'lg'
  }
}

export default {
  name: 'CustomerClientDataForm',
  props: {
    isFormValid: Boolean
  },
  components: { DeepSettingsFieldHOC, FormTitle },
  methods: {
    ...mapActions('moderator', {
      updateCustomerClientDataParam: 'updateCustomerClientDataParam'
    }),
    updateParam(val, param) {
      this.updateCustomerClientDataParam({
        param,
        val
      })
    }
  },
  watch: {
    computedIsFormValid: {
      handler(newVal) {
        this.$emit('update:isFormValid', newVal)
      },
      deep: true,
      immediate: true
    }
  },
  computed: {
    ...mapState('moderator', {
      fields: state => state.customerClientData
    }),
    formConfigs: () => formConfigs,
    isClientFieldsNeed() {
      return this.currentFormConfig.hasOwnProperty('client')
    },
    currentFormConfig() {
      const { customer, clientType } = this.fields
      const [currentConfig] = [
        config1Variants.includes(customer) && !clientType && formConfigs.configDefault,
        config1Variants.includes(customer) &&
          config1SubConfig1Variants.includes(clientType) &&
          formConfigs.formConfig1.subConfig1,
        config1Variants.includes(customer) &&
          config1SubConfig2Variants.includes(clientType) &&
          formConfigs.formConfig1.subConfig2,
        config1Variants.includes(customer) &&
          config1SubConfig3Variants.includes(clientType) &&
          formConfigs.formConfig1.subConfig3,
        config2Variants.includes(customer) && formConfigs.formConfig2,
        config3Variants.includes(customer) && formConfigs.formConfig3,
        config4Variants.includes(customer) && formConfigs.formConfig4,
        formConfigs.configDefault
      ].filter(Boolean)
      return currentConfig
    },
    customerOrClientsDataFieldsConfigs: () => customerOrClientsDataFieldsConfigs,
    requiredFields() {
      const commonRequiredFields = [customer]
      return this.isClientFieldsNeed ? [...commonRequiredFields, clientType] : commonRequiredFields
    },
    computedIsFormValid() {
      return checkIsFormValid(this.fields, this.requiredFields)
    }
  }
}
</script>

<style scoped lang="sass">
.customer-client-data
  display: grid
  +media((grid-template-columns: (768: repeat(2, 1fr), 1600: repeat(3, 1fr))))
  gap: rem(24) rem(50)
  max-width: rem(1080)
  .form-title
    &:not(:first-child)
      margin: rem(40) 0 rem(8)
    margin: 0 0 rem(8)
    +media((grid-column: (768: span 2, 1600: span 3)))
    // width: 100%
</style>
